import React, { forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import Layer from "./components/Layer";
import PopupShopOverview from "./components/PopupShopOverview";
import PopupShopGetDirection from "./components/PopupShopGetDirection";

import { getViewBoxProperty } from "~/utils";

/**
 * ----------------------------------------------------------------------------
 * Floor plan
 * ----------------------------------------------------------------------------
 *
 * @param  {{ data: { floorplan, path, name }, renderRoutingPath: function }} props
 * @param  {String} props.viewBox
 * @param  {Function} props.renderRoutingPath
 * @
 */
const FloorPlan = forwardRef(
  (
    {
      data: { id, floorType, floorplan, path, pins, activeUnits, popup },
      originActiveStyle,
      destinationActiveStyle,
      routingPath,
      renderRoutingPath,
      mapPin,
      renderPin,
      facilitiesLayer,
      shopsLayer,
      onClickUnit,
      onMount,
    },
    ref
  ) => {
    const { width, height } = getViewBoxProperty(floorplan.viewBox);
    useEffect(() => {
      if (onMount) {
        onMount();
      }
    }, [id]);
    return (
      <Wrapper
        ref={ref}
        version="1.1"
        xmlns="https://www.w3.org/2000/svg"
        className="floor-plan"
        viewBox={floorplan.viewBox}
      >
        <image
          id={`floor-plan-${id}`}
          xlinkHref={floorplan.src}
          x={0}
          y={0}
          width={width}
          height={height}
          style={{ pointerEvents: "none" }}
        />

        {/* render facilities layer */}
        {facilitiesLayer && <Layer units={floorplan.facilities} />}

        {/* render shops layer */}
        {shopsLayer && (
          <Layer
            units={floorplan.shops}
            activeUnits={activeUnits}
            originActiveStyle={originActiveStyle}
            destinationActiveStyle={destinationActiveStyle}
            onClickUnit={onClickUnit} // onClickUnit
          />
        )}

        {/* render navigation path */}
        {routingPath && renderRoutingPath(path, floorType)}

        {/* render pin on map */}
        {mapPin && pins.map((pin, index) => renderPin(pin, index))}

        {popup && popup.type === "basic" && popup.data && (
          <PopupShopOverview {...popup.data} />
        )}
        {popup && popup.type === "get-direction" && popup.data && (
          <PopupShopGetDirection {...popup.data} />
        )}
      </Wrapper>
    );
  }
);

const Wrapper = styled.svg``;

FloorPlan.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    floorplan: PropTypes.shape({
      src: PropTypes.string,
      viewBox: PropTypes.string,
      name: PropTypes.shape(),
      facilities: PropTypes.arrayOf(PropTypes.shape()),
      shops: PropTypes.arrayOf(PropTypes.shape()),
    }),
    path: PropTypes.string,
    floorType: PropTypes.string,
    pins: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(["origin", "destination"]),
        from: PropTypes.shape({
          floorId: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          neighbor: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
          x: PropTypes.string.isRequired,
          y: PropTypes.string.isRequired,
        }),
        to: PropTypes.shape({
          floorId: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          neighbor: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
          x: PropTypes.string.isRequired,
          y: PropTypes.string.isRequired,
        }),
        radius: PropTypes.number,
      })
    ),
    activeUnits: PropTypes.arrayOf(PropTypes.shape()),
    type: PropTypes.string,
    popup: PropTypes.shape({
      type: PropTypes.oneOf(["basic", "get-direction"]),
      data: PropTypes.shape({}),
    }),
    onMount: PropTypes.func,
  }),
  originActiveStyle: PropTypes.shape(),
  destinationActiveStyle: PropTypes.shape(),
  routingPath: PropTypes.bool,
  renderRoutingPath: PropTypes.func,
  mapPin: PropTypes.bool,
  renderPin: PropTypes.func,
  facilitiesLayer: PropTypes.bool,
  shopsLayer: PropTypes.bool,
  onClickUnit: PropTypes.func,
};

FloorPlan.defaultProps = {
  data: {},
  originActiveStyle: {},
  destinationActiveStyle: {},
  routingPath: true,
  renderRoutingPath() {},
  mapPin: true,
  renderPin() {},
  facilitiesLayer: true,
  shopsLayer: true,
  onClickUnit: () => {},
  onMount: () => {},
};

export default FloorPlan;
