import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import pathToRegex from 'path-to-regexp';

import config from '~/lib/config';
import join from '~/lib/join';
import logger from '~/lib/logger';
import { useTranslation, useWayfinder, useTheme, useAction } from '~/hooks';
import { DirectionStrategy } from '~/enums/direction-strategy';
import { ProjectAction } from '~/actions/project';
import { SearchAction } from '~/actions/search';
import { ContentAction } from '~/actions/content';
import { BuildingAction } from '~/actions/building';

// will be remove soon
import contentActions from '~/store/actions/Content';
import domainActions from '~/store/actions/Domain';
import searchActions from '~/store/actions/Search';
import projectActions from '~/store/actions/Project';
import { connectRedux, toSelectOptions, toArray } from '~/utils';
import { WAYFINDER_METHOD_OVERVIEW, WAYFINDER_METHOD_STEP_AND_MORE } from '~/app/variables';
import theme from '~/apis/Theme';
import { ProjectService, ContentService } from '~/services';
import fetch from '~/lib/fetcher';

import { Switch, Loading } from './components';
import transformNode from './lib/transform-node';

const mapActionToProps = {
  ...contentActions,
  ...domainActions,
  ...projectActions,
  ...searchActions,
};

/*
 * @constant
 * @description regex pathname
 */
const PATH_PATTERN = `${config.PUBLIC_URL}/:lang/:slug/(.*)`;

/**
 * Loading switch component
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @return {JSX.Element}
 */
function LoadingSwitch ({ actions, children }) {
  const [ percentage, setPercentage ] = useState(0);
  const [ visible, setVisible ] = useState(true);

  const { translate, changeLanguage } = useTranslation();
  const { setNodes } = useWayfinder();
  const { changeTheme } = useTheme();

  const { helmet, logo } = useSelector(state => state.project);
  const Project = useAction(ProjectAction);
  const Search = useAction(SearchAction);
  const Content = useAction(ContentAction);
  const Building = useAction(BuildingAction);

  const onDownloadProgress = ({ loaded, total }) => {
    const percent = Math.round((loaded * 100) / total);

    setPercentage(percent === Infinity ? 100 : percent);
  };

  /**
   * Init with API
   *
   * @description fetch resource from API
   * @return {Promise<void>}
   */
  const initWithAPI = async () => {
    const regex = pathToRegex(PATH_PATTERN);
    const [ _, lang, slug ] = regex.exec(window.location.pathname); // eslint-disable-line no-unused-vars

    changeLanguage(lang ?? config.FALLBACK_LANGUAGE);

    // loading credential for target project
    const credential = await import(`../../assets/keys/${slug}.json`); // import api key for specific project
    fetch.defaults.headers.common['key'] = credential.apiKey;          // set default api key to fetcher (axios instance)

    const [ project, content ] = await Promise.all([ ProjectService.get({ $populate: ['logo']}), ContentService.find(null, { onDownloadProgress }) ]);

    Project.set(project);
    Content.set(content);
    Search.setDirectionStrategy(DirectionStrategy.search(project.defaultDirectionStrategy));

    changeTheme(project.theme);
    setNodes(transformNode(content.nodes));
  };

  /**
   * Init with JSON
   *
   * @description fetch resource from JSON
   * @return {Promise<void>}
   */
  const initWithJSON = async () => {
    const regex = pathToRegex(PATH_PATTERN);
    const [ _, lang, projectId ] = regex.exec(window.location.pathname); // eslint-disable-line no-unused-vars
    logger.debug('path variable', { lang, projectId });

    logger.debug(`establish web directory for '${projectId}' project`);
    actions.setProjectId(projectId);

    const { defaultWayFinderMethod } = await actions.getProjectByProjectId(projectId);
    logger.debug(`use '${defaultWayFinderMethod}' as the direction strategy`);
    switch (defaultWayFinderMethod) {
      case WAYFINDER_METHOD_OVERVIEW:
        actions.setWayFinderMethodOverview();
        break;

      case WAYFINDER_METHOD_STEP_AND_MORE:
        actions.setWayFinderMethodStepAndMore();
        break;

      default:
        // do nothing
        break;
    }

    const language = lang ?? config.FALLBACK_LANGUAGE;
    changeLanguage(language);
    logger.debug(`use '${language}' language`);

    const themeResponse = await theme.getThemeByProjectId(projectId);
    changeTheme(themeResponse);
    logger.debug('fetching theme', themeResponse);

    await actions.getFloorIcon(projectId);
    const response = await actions.getContentByProjectId(projectId, onDownloadProgress);

    await actions.getMovementMedia();
    logger.debug(`use '${Object.keys(response.iam_projects)[0]}' project account`);
    actions.setProjectAccount(Object.keys(response.iam_projects)[0]);

    if (Object.keys(response).length > 1) {
      const buildings = toSelectOptions(toArray(response.building, 'asc'), building => translate(building.name), building => building.id);
      console.log('buildings', buildings);
      Building.set(buildings[0]);
    }

    setNodes(response.nodes);
  };

  const init = async () => {
    try {
      const resource = config.BASE_URL ? 'api' : 'json';
      logger.debug(`fetch resource from ${resource} ${config.BASE_URL ?? ''}`);

      switch (resource) {
        case 'api':
          await initWithAPI();
          break;

        default:
        case 'json':
          await initWithJSON();
          break;
      }

      setTimeout(() => setVisible(false), 2000);
    } catch (error) {
      logger.error(error);

      setVisible(false);
    }
  };

  /**
   * set --vh for calc the real height of document (height in page-basis mixin)
   * more info (@link https://css-tricks.com/the-trick-to-viewport-units-on-mobile/)
   */
  const setRealHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    init();
    setRealHeight();

    window.addEventListener('resize', setRealHeight);

    return () => {
      window.removeEventListener('resize', setRealHeight);
    };
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="shortcut icon" href={helmet?.favicon} type="image/x-icon" />
        <link rel="icon" href={helmet?.favicon} type="image/x-icon" />
        <title>{translate(helmet?.title)}</title>
      </Helmet>
      <Loading
        spinning={visible}
        src={join.public(logo)}
        progress={percentage}
        text={translate('loading')}
      >
        <Switch>
          {children}
        </Switch>
      </Loading>
    </Fragment>
  );
}

LoadingSwitch.propTypes = {
  actions: PropTypes.shape(),
  children: PropTypes.node.isRequired,
};

LoadingSwitch.defaultProps = {
  actions: {},
};

export default connectRedux(null, mapActionToProps)(LoadingSwitch);
