import React from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Button } from "~/components";

import { useTheme, useTranslation, useRouter } from "~/hooks";

import { domainSelector } from "~/store/selector";

export default () => {
  const { theme } = useTheme();
  const { translate, currentLanguage } = useTranslation();
  const { push } = useRouter();
  const { projectId } = useSelector(
    createStructuredSelector({
      projectId: domainSelector.projectId,
    })
  );
  return (
    <Wrapper className="home-button" theme={theme}>
      <Button
        className="is-secondary"
        size="small"
        leftIcon="Search"
        title={translate("search")}
        fullWidth={false}
        rounded={false}
        onClick={() => {
          push({
            regexPath: "/:lang/:projectId/explore",
            params: {
              projectId,
              currentLanguage,
            },
          });
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
