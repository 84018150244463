import { createSelector } from 'reselect';
import intersectionBy from 'lodash.intersectionby';
import helper from '../helper';
import { slugify, random } from '~/utils';

/**
 * Entire shop
 * @description entire shop from redux store
 */
const entire = helper.getEntireShop;

/**
 * Shops
 * @description shops which convert to array
 */
const shops = createSelector(
  [helper.getEntireShop],
  entireShop => Object.entries(entireShop).reduce((result, shop) => ([ ...result, { id: shop[0], ...shop[1] }]), []),
);

/**
 * Shop for searching
 * @description Get shop which prepare for searching feature (origin and destination search)
 */
const shopsForSearching = createSelector(
  [ shops, helper.getEntireFloor, helper.getEntireMedia, helper.getEntireCategory ],
  (shops, floors, medias, categories) => shops.reduce((result, shop) => {
    const { name, logo, floor, id } = shop;
    const category = Object.keys(categories).find(categoryId => `${categoryId}` === `${shop.categories[0]}`);
    const isDuplicate = result.find(item => item.id === id);
    return !isDuplicate
      ? ([ ...result,
        {
          ...shop,
          id,
          floor: floors[floor],
          label: name,
          logo,
          category: category ? category.title : {},
        },
      ])
      : result;
  }, []),
);

/**
 * Get shop by id
 * @description Get a single shop by provide id
 * @param {String|Number} id
 */
const getShopById = createSelector(
  helper.getEntireShop,
  entireShop => id => entireShop[id],
);

/**
 * Get shop by name and floor
 * @description Get a single shop by provied name and its floor
 * @param {String} name
 * @param {String} floor
 */
const getShopByNameAndFloor = createSelector(
  [shops],
  shops => (name, floor) => (
    shops.find((shop) => {
      const trimAndLowerCaseName = name.trim().toLowerCase();
      const names = Object.values(shop.name).map(name => slugify.encode(name.trim().toLowerCase()));

      return names.indexOf(trimAndLowerCaseName) !== -1 && shop.floor === floor;
    })
  ),
);

/**
 * Get shop by name
 * @description Get a single shop by provide shop name. This function similar to getShopByNameAndFloor, but receive one argument(name).
 * @param {String} name
 */
const getShopByName = createSelector(
  [shops],
  shops => name => (
    shops.find((shop) => {
      const trimAndLowerCaseName = name.trim().toLowerCase();
      const names = Object.values(shop.name).map(name => slugify.encode(name.trim().toLowerCase()));

      return names.indexOf(trimAndLowerCaseName) !== -1;
    })
  ),
);

/**
 * Get shop by category
 * @description Get a list of shop by provide category
 * @param {(String|Number)} category
 */
const getShopsByCategory = createSelector(
  [shops],
  shops => category => shops.filter(shop => shop.category === category),
);

const getShopsByCategoryAndFloor = createSelector(
  [shops],
  shops => (category, floor) => shops.filter(shop => shop.category === category && shop.floor === floor),
);

/**
 * Get random shops by category
 *
 * @param {number} category
 * @param {number} counts
 */
const getRandomShopsByCategory = createSelector(
  [shops],
  shops => (category, counts) => {
    const filteredShops = shops.filter(shop => shop.category === category);
    const randoms = random(counts, filteredShops.length);

    return randoms.reduce((result, item) => ([
      ...result,
      filteredShops[item],
    ]), []);
  },
);

/**
 * Get related shops
 *
 * @param {Object} targetShop
 * @param {number} counts
 */
const getRelatedShopsOfShop = createSelector(
  [shops],
  shops => (targetShop, counts) => {
    // filter related shops
    const targetShopSlug = targetShop.slug;
    const targetShopKeywords = targetShop.keywords;
    if (!targetShopSlug || !targetShopKeywords) return random(counts, shops.length);
    const filteredShops = shops.filter(({ slug, keywords }) => {
      const sameKeywords = intersectionBy(keywords, targetShopKeywords);
      const numberOfSameKeyword = sameKeywords.length;
      return numberOfSameKeyword > 0 && slug !== targetShopSlug;
    });
    const randoms = filteredShops.length > counts ? random(counts, filteredShops.length).reduce((acc, randomIndex) => {
      return [
        ...acc,
        shops[randomIndex],
      ];
    }, []) : filteredShops;
    return randoms;
  },
);


export default {
  entire,
  shops,
  shopsForSearching,
  getShopById,
  getShopByNameAndFloor,
  getShopByName,
  getShopsByCategory,
  getShopsByCategoryAndFloor,
  getRandomShopsByCategory,
  getRelatedShopsOfShop,
};
