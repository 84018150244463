import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { isMobile } from 'react-device-detect';
import useRouter from 'use-react-router';

import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useTheme, useTranslation } from '~/hooks';
import { FloorPlan, PinchZoomPan } from '~/components';
import { floorSelector, shopSelector, categorySelector } from '~/store/selector';

import { getViewBoxProperty, isEmpty, getFile, slugify } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Floor map component
 * ----------------------------------------------------------------------------
 *
 * @returns
 */
function FloorMap ({ floorKey, popUpUnit, onClickGetDirection }) {
  const {
    shops,
    getFloorWhichPrepareToRenderOnFloorPlan,
    getCategoryById,
    getFloorById,
  } = useSelector(
    createStructuredSelector({
      shops: shopSelector.shops,
      getFloorWhichPrepareToRenderOnFloorPlan: floorSelector.getFloorWhichPrepareToRenderOnFloorPlan,
      getCategoryById: categorySelector.getCategoryById,
      getFloorById: floorSelector.getFloorById,
    }),
  );

  const { match: { params: { projectId } } } = useRouter();

  const { theme } = useTheme();
  const { translate } = useTranslation();
  const [ selectedShop, setSelectedShop ] = useState({});
  const [ popup, setPopup ] = useState({});
  const [ targetPopUp, setTargetPopup ] = useState(null);

  const pinchZoomRef = useRef(null);
  const floorRef = useRef(null);
  const wrapperRef = useRef(null);

  /**
   * Floor of destination shop
   * @constant
   */
  const floor = getFloorWhichPrepareToRenderOnFloorPlan(floorKey);

  const zoomToCenterMap = (scale = 1.2) => {
    const { width: bodyWidth } = wrapperRef.current.getBoundingClientRect();
    const { viewBox: originViewBox } = floor;
    const { height: originViewBoxHeight, width: originViewBoxWidth } = getViewBoxProperty(originViewBox);
    const originPoint = {
      x: originViewBoxWidth / 2,
      y: originViewBoxHeight / 2,
      width: 10,
      height: 10,
    };
    const midpoint = {
      x:
        (originPoint.x + originPoint.width / 2)
        / (originViewBoxWidth / bodyWidth),
      y:
        (originPoint.y + originPoint.height / 2)
        / (originViewBoxWidth / bodyWidth),
    };
    if (pinchZoomRef) {
      pinchZoomRef.current.zoomByPosition(scale, midpoint, 0, 2000);
    }
  };

  const handleSelectUnit = (unitId) => {
    const targetShop = shops.find(shop => shop.unit === unitId);
    // console.log('shops >>> ', shops, unitId);
    // console.log('unitId >>> ', unitId);
    if (targetShop) {
      setSelectedShop(targetShop);
      popUpUnit(targetShop);
      setTargetPopup(targetShop);
    } else {
      // alert(`shop of ${unitId} was not found.`);
      console.warn(`shop of ${unitId} was not found.`); // eslint-disable-line
      popUpUnit(null);
      setTargetPopup(null);
    }
  };

  // const handleClickDirection = () => {
  //   console.log('handle click direction >>>> ', history);
  //   const shopNameInCurrentLanguage = translate(targetPopUp.name);
  //   /* push navigation */
  //   push({
  //     regexPath: '/:lang/:projectId/explore/:destination',
  //     params: {
  //       projectId,
  //       lang,
  //       destination: `${slugify.encode(shopNameInCurrentLanguage)}$${
  //         targetPopUp.floor
  //       }`,
  //     },
  //   });
  // };

  const activeUnit = isEmpty(selectedShop.unit)
    ? {}
    : { unitNo: selectedShop.unit, type: 'destination' };

  useEffect(() => {
    const unitDOM = document.getElementById(`${selectedShop.unit}`);
    if (!isEmpty(unitDOM)) {
      const BBox = unitDOM.getBBox();
      setPopup({
        type: 'basic',
        data: BBox && {
          x: BBox.x + (BBox.width / 2),
          y: BBox.y + (BBox.height / 2),
          logo: selectedShop.logo,
          title: translate(selectedShop.title),
          subTitle: translate(getCategoryById(selectedShop.categories[0]).title),
          text: translate(getFloorById(selectedShop.floor).fields.name),
        },
      });
    }
  }, [selectedShop]);

  useEffect(() => {
    setPopup({});
    if (isMobile && pinchZoomRef) {
      setTimeout(() => {
        // zoomToCenterMap(2);
      }, 1500);
    }
  }, [floorKey]);

  return (
    <Wrapper className="floor-map" ref={wrapperRef} theme={theme}>
      {floor && (
        <PinchZoomPan
          initialTop={0}
          initialLeft={0}
          minScale={1}
          maxScale={10}
          zoomButtons
          ref={pinchZoomRef}
          onClickResetZoom={zoomToCenterMap}
          onTouchDown={handleSelectUnit}
        >
          <FloorPlan
            ref={floorRef}
            data={{
              floorType: 'destination',
              floorplan: floor,
              activeUnits: [activeUnit],
              pins: [],
              popup,
            }}
            onClickUnit={handleSelectUnit}
          />
        </PinchZoomPan>
      )}
      {targetPopUp !== null && !isMobile && (
        <PopupSection className="popup-direction" onClick={() => onClickGetDirection(selectedShop)}>
          <PopupLogo src={(selectedShop.logo) ? selectedShop.logo : getFile(`/images/${projectId}/shop-no-logo.png`)} alt="" />
          <TextDiv style={{ marginLeft: 5, lineHeight: 1 }}>GET DIRECTION</TextDiv>
        </PopupSection>
      )}
    </Wrapper>
  );
}

FloorMap.propTypes = {
  floorKey: PropTypes.number.isRequired,
  popUpUnit: PropTypes.func.isRequired
};

const Wrapper = styled.div`
  position: relative;
  .pinch-pan-zoom-button {
    &:hover {
      background-color: ${({ theme }) => theme.colorHelper.secondary};
      border: 1px solid ${({ theme }) => theme.colorHelper.secondary};
    }
  }
`;

const PopupSection = styled.div`
  position: absolute;
  top: 20px;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: flex-end;
  justify-content: center;
  align-items: center;
`;

const PopupLogo = styled.img`
  width: 20%;
  height: auto;
  margin-left: 10px;
`;

const TextDiv = styled.div`
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  text-align: center;
`;

export default FloorMap;
