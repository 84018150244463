import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { visibilityFilterSelector, domainSelector, shopSelector, projectSelector, floorSelector, buildingSelector } from '~/store/selector';

import { useRouter, useSelector, useTranslation } from '~/hooks';

import { Column, ListItem, FloorItem } from '~/components';

import FloorMap from './components/FloorMap';

function FloorSelector ({ popUpUnit, onClickGetDirection }) {
  const {
    project,
    activeBuilding,
    floors: mainBuildingFloors,
    getFloorsByBuildingId,
    hasManyBuildings,
  } = useSelector({
    domain: domainSelector.entire,
    getShopById: shopSelector.getShopById,
    project: projectSelector.entire,
    categoryFilter: visibilityFilterSelector.category,
    activeBuilding: buildingSelector.activeBuilding,
    floors: floorSelector.floors,
    getFloorsByBuildingId: floorSelector.getFloorsByBuildingId,
    hasManyBuildings: buildingSelector.hasManyBuildings,
  });

  const { translate } = useTranslation();
  const [ activeFloor, setActiveFloor ] = useState('1');
  const { match: { params: { projectId } } } = useRouter();
  const activeBuildingObject = activeBuilding(translate);

  const { defaultFloor } = project;

  const { defaultFloor: defaultBuildingFloor } = activeBuildingObject;

  const floors = useMemo(() => {
    const sortByDescFloors = hasManyBuildings ? getFloorsByBuildingId(activeBuildingObject.value) : mainBuildingFloors;
    if (sortByDescFloors.length > 5) {
      return sortByDescFloors.reverse();
    }
    return sortByDescFloors;
  }, [activeBuilding(translate).value]);

  useEffect(() => {
    if (defaultBuildingFloor && hasManyBuildings) {
      setActiveFloor(defaultBuildingFloor);
    } else if (defaultFloor) {
      setActiveFloor(defaultFloor);
    } else if (floors && floors[0] && floors[0].id) {
      setActiveFloor(floors[0].slug);
    }
  }, [ floors.length, activeBuilding(translate).value ]);

  const hasMoreThanFiveFloor = floors.length > 5;

  const handleSelectActiveFloor = (floorKey) => {
    setActiveFloor(floorKey);
  };

  const handleUnitPopUp = (shop) => {
    // console.log('handleUnitPopUp >>> ', shop);
    popUpUnit(shop);
  };

  const renderFloorItem = floor => (
    <Column.Item gaps={1}>
      <FloorItem
        name={floor?.fields?.name?.en}
        className={projectId}
        floorKey={floor?.slug}
        onClick={() => handleSelectActiveFloor(floor?.slug)}
        active={floor?.slug === activeFloor}
      />
    </Column.Item>
  );

  const renderVerticalFloorItem = floor => (
    <div className="D-12 M-3">
      <FloorItem
        className={projectId}
        floorKey={floor.slug}
        onClick={() => handleSelectActiveFloor(floor.slug)}
        active={floor.slug === activeFloor}
      />
    </div>
  );

  if (hasMoreThanFiveFloor) {
    return (
      <>
        <ListItem
          items={floors}
          keyExtractor={(item) => item[0]}
          render={renderFloorItem}
          containerClassName="floor-selector-list"
          injectContainerProp={{ mobileScrollable: true }}
          renderAs={Column}
        />
        <FloorMap floorKey={activeFloor} popUpUnit={handleUnitPopUp} />
      </>
    );
  }

  return (
    <div className="row">
      <div className="D-2 M-12 vertical-middle">
        <div className="row">
          {
            floors.map(renderVerticalFloorItem)
          }
        </div>
      </div>
      <div className="D-10 M-12 vertical-middle">
        <FloorMap
          floorKey={activeFloor}
          popUpUnit={handleUnitPopUp}
          onClickGetDirection={onClickGetDirection}
        />
      </div>
    </div>
  );
}

FloorSelector.propTypes = {
  popUpUnit: PropTypes.func.isRequired
};

export default FloorSelector;
